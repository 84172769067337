import {Controller} from '@hotwired/stimulus'
import {debounce} from 'debounce'

export default class extends Controller {
    static targets = ['input', 'validateCustomInputsTrigger']
    static values = {
        debug: {type: Boolean, default: false},
        disableSubmitButtons: {type: Boolean, default: false}
    }

    initialize() {
        this.validateInput = debounce(this.validateInput, 500)
        this._handleFormChange = debounce(this._handleFormChange.bind(this), 500)
    }

    connect() {
        this._addEventListeners()
        this._validateAllCustomInputs()
        if (this.disableSubmitButtonsValue) this._toggleSubmitButtons(this.element.checkValidity())
    }

    disconnect() {
        this._removeEventListeners()
    }

    // noinspection JSUnusedGlobalSymbols
    validateCustomInputsTriggerTargetDisconnected() {
        this._log('a validateCustomInputsTrigger disconnected')
        this._validateAllCustomInputs()
    }

    validateInput(event) {
        const input = event.target
        this._log(`validateInput called for ${input.name}`)
        input.dataset.validates.split(' ').forEach(validator => {
            this[`_${validator}`](input)
        })
    }

    _validateAllCustomInputs() {
        this._log('_validateAllCustomInputs called')
        Array.prototype.forEach.call(this.inputTargets, (input, _index) => {
            input.dataset.validates.split(' ').forEach(validator => {
                this[`_${validator}`](input)
            })
        })
    }

    _validateAllOtherCustomInputs(event) {
        this._log('_validateAllOtherCustomInputs called')
        let otherInputs = this.inputTargets
        let indexOfTarget = otherInputs.indexOf(event.target)
        if (indexOfTarget > -1) otherInputs.splice(indexOfTarget, 1)
        Array.prototype.forEach.call(otherInputs, (input, _index) => {
            input.dataset.validates.split(' ').forEach(validator => {
                this[`_${validator}`](input)
            })
        })
    }

    _addEventListeners() {
        this.element.addEventListener('input', this._handleFormChange)
        this.element.addEventListener('change', this._handleFormChange)
    }

    _removeEventListeners() {
        this.element.removeEventListener('input', this._handleFormChange)
        this.element.removeEventListener('change', this._handleFormChange)
    }

    _handleFormChange(event) {
        this._log('_handleFormChange called')
        this._validateAllOtherCustomInputs(event)
        if (this.disableSubmitButtonsValue) this._toggleSubmitButtons(event.currentTarget.checkValidity())
    }

    _toggleSubmitButtons(bool) {
        this._log(`_toggleSubmitButtons called with '${bool}'`)
        Array.prototype.forEach.call(this.element.querySelectorAll('[type="submit"]'), (input, _index) => {
            input.disabled = !bool
        })
    }

    _log(message) {
        if (this.debugValue === true) console.log(`DEBUG validation: ${message}`)
    }

    // noinspection JSUnusedGlobalSymbols
    _requireWhenOtherFieldNotEmpty(input) {
        const otherField = this.element.querySelector(input.dataset.otherFieldSelector)
        if (['checkbox', 'radio'].includes(otherField.type)) {
            input.required = otherField.checked
        } else {
            input.required = otherField.value !== ''
        }
    }

    // noinspection JSUnusedGlobalSymbols
    _requireWhenOtherFieldEmpty(input) {
        const otherField = this.element.querySelector(input.dataset.otherFieldSelector)
        if (['checkbox', 'radio'].includes(otherField.type)) {
            input.required = !otherField.checked
        } else {
            input.required = otherField.value === ''
        }
    }

    // noinspection JSUnusedGlobalSymbols
    _fieldValueEquality(input) {
        const otherField = this.element.querySelector(input.dataset.equalFieldSelector)
        const inputValid = input?.value === otherField?.value
        this._setCustomValidity(input, inputValid)
    }

    // noinspection JSUnusedGlobalSymbols
    _minValueOfOtherField(input) {
        const otherField = this.element.querySelector(input.dataset.minValueFieldSelector)
        input.min = parseInt(otherField.value)
    }

    // noinspection JSUnusedGlobalSymbols
    _remoteValidation(input) {
        if (input.value) {
            const ajaxData = input.dataset.ajaxDataParams + input.value
            Rails.ajax({
                type: 'GET', url: input.dataset.remoteValidationUrl, data: ajaxData,
                success: (response) => {
                    this._setCustomValidity(input, response)
                }
            })
        } else {
            this._setCustomValidity(input, true)
        }
    }

    // noinspection JSUnusedGlobalSymbols
    _notInListOfGroup(input) {
        // Get all other elements of the form that must be unique from given query selector.
        const inputs = this.element.querySelectorAll(input.dataset.notInListOfGroupSelector)
        this._checkForNotInListOfGroup(inputs, input);

        let otherInputs = [...inputs]
        let indexOfTarget = otherInputs.indexOf(input)
        if (indexOfTarget > -1) otherInputs.splice(indexOfTarget, 1)
        Array.prototype.forEach.call(otherInputs, (input, _index) => {
            this._checkForNotInListOfGroup(inputs, input);
        })
    }

    // noinspection JSUnusedGlobalSymbols
    _fileSize(input) {
        let maxFileSize = input.dataset.maxFileSize
        let file = input.files[0]
        this._setCustomValidity(input, !(file && file.size > maxFileSize))
    }

    // Extracted method to make checks for unique list more convenient.
    // @param inputs: a list of input nodes whose values will be checked.
    // @param input: given input node that triggered the check.
    _checkForNotInListOfGroup(inputs, input) {
        // Map the value of given inputs.
        let values = [].map.call(inputs, input => input.value)
        // Find first match of current input value in given array and remove it if found.
        const index = values.indexOf(input.value)
        if (index > -1) values.splice(index, 1)
        // Check if current input value is still in the list and return false if so.
        this._setCustomValidity(input, !values.includes(input.value))
    }

    _setCustomValidity(element, response) {
        if (typeof response == 'boolean')
            if (element.title.length > 0) {
                response ? element.setCustomValidity('') : element.setCustomValidity(element.title)
            } else {
                this._log('cannot assign custom validity because element title is not present')
            }
        else {
            element.setCustomValidity(response)
        }
    }
}
