import {Controller} from '@hotwired/stimulus'

import 'mediaelement'
import 'mediaelement/build/lang/de'
import 'mediaelement/build/renderers/vimeo'

export default class extends Controller {
    static values = {iconSpritePath: String}

    initialize() {
        mejs.i18n.language(document.documentElement.lang)
    }

    connect() {
        const elements = this.element.querySelectorAll('oembed')
        elements.forEach(e => this._loadVideo(e))
    }

    _loadVideo(oembed) {
        const video = document.createElement('video')
        video.setAttribute('src', oembed.getAttribute('url'))
        video.style.width = '100%'
        video.style.height = '100%'
        oembed.parentNode.appendChild(video)

        new MediaElementPlayer(video, {
            renderers: ['youtube_iframe', 'vimeo_iframe'],
            features: ['playpause', 'progress', 'current', 'duration', 'tracks', 'volume', 'fullscreen'],
            iconSprite: this.iconSpritePathValue,
            autoRewind: false,
            youtube: {
                nocookie: true
            }
        })
    }
}
