class TutorizeUploadAdapter {
    constructor(loader, url) {
        this.loader = loader
        this.url = url
    }

    upload() {
        return this.loader.file
            .then(file => {
                const data = new FormData()
                data.append('media_file[media]', file)

                return fetch(this.url, {
                    credentials: 'same-origin',
                    headers: {'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`},
                    method: 'POST',
                    body: data
                })
                    .then(r => (r.ok ? r.json() : Promise.reject(r)))
                    .then(data => {
                        return {
                            urls: {
                                ['default']: data.link
                            }
                        }
                    })
            })
    }

    abort() {
    }
}

export function TutorizeUploadAdapterPlugin(editor, url) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        // Configure the URL to the upload script in your back-end here!
        return new TutorizeUploadAdapter(loader, url)
    }
}
