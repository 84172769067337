import {Controller} from '@hotwired/stimulus'

import AsyncPick from 'async-pick'

export default class extends Controller {
    static values = {
        urlOption: String,
        emptySelectButtonTextOption: String,
        containerOption: String,
        searchParamOption: String,
        jsonKeyOption: String,
        dropdownAlignRightOption: Boolean,
        selectedTextFormatOption: String,
        resetOnCloseOption: Boolean,
        withSearchOption: Boolean
    }

    reconnect() {
        this._async_pick.destroy()
        this.load()
    }

    connect() {
        this.load()
        this.element['asyncPick'] = {
            reconnect: this.reconnect.bind(this),
            values: this._async_pick.values
        }
    }

    load() {
        let options = this.options
        Object.keys(options).forEach(function (key) {
            if (options[key] === "") delete options[key]
        })

        const elementId = this.element.getAttribute('id')
        this._async_pick = new AsyncPick(Object.assign({}, this.defaultOptions, {id: elementId}, options))
    }

    get options() {
        return {
            emptySelectButtonText: this.emptySelectButtonTextOptionValue,
            url: this.urlOptionValue,
            container: this.containerOptionValue,
            searchParam: this.searchParamOptionValue,
            jsonKey: this.jsonKeyOptionValue,
            dropdownAlignRight: this.hasDropdownAlignRightOptionValue ? this.dropdownAlignRightOptionValue : "",
            selectedTextFormat: this.selectedTextFormatOptionValue,
            resetOnClose: this.hasResetOnCloseOptionValue ? this.resetOnCloseOptionValue : "",
            withSearch: this.hasWithSearchOptionValue ? this.withSearchOptionValue : true
        }
    }

    get defaultOptions() {
        return {
            language: document.documentElement.lang,
            pageParam: 'page',
            perPageParam: 'per_page',
            jsonKey: 'data',
            searchParam: 'q[username_or_email_or_full_name_or_full_name_reverse_cont_any]',
            httpMethod: 'GET',
            maxPages: 2,
            perPage: 50,
            paginateUpThreshold: 10,
            paginateDownThreshold: 90,
            searchTimeout: 400,
            searchInputClasses: ['form-control'],
            withSearch: true,
            buttonClasses: ['form-select'],
            buttonDisabledClasses: ['btn-disabled'],
            buttonIconClasses: [],
            checkedIconClasses: ['fas', 'fa-fw', 'fa-check'],
            listClasses: [],
            selectedTextFormat: 'count>3',
            selectedTextVariable: '%num%',
            resetOnClose: true,
            container: false,
            dropdownAlignRight: false,
            popupWidth: '300px',
            values: null
        }
    }
}
