/*
  Filterable Stimulus Controller v0.0.1

  You can filter all kinds of Dom Elements from given an input element which triggers the filter function.
  Every element specified with the 'data-filterable-target="element"' attribute will be considers for the filter.
  You can set a 'data-filterable-target="curriculum"' in which a text is displayed when the filter does not match any
  element.
 */
import {Controller} from '@hotwired/stimulus'
import {debounce} from 'debounce';

export default class extends Controller {
    static values = {noEntriesHint: {type: String, default: '<p class="text-center text-muted">No entries found</p>'}}
    static targets = ['element', 'curriculum']

    initialize() {
        this.filter = debounce(this.filter, 500)
    }

    connect() {
        let template = document.createElement('template')
        template.innerHTML = this.noEntriesHintValue.trim()
        this._noEntriesHint = template.content.firstChild
    }

    filter(event) {
        const inputValue = event.target.value.trim().toLowerCase()
        Array.prototype.forEach.call(this.elementTargets, function (element) {
            if (element.textContent.toLowerCase().indexOf(inputValue) > -1) {
                element.classList.remove('visually-hidden')
            } else {
                element.classList.add('visually-hidden')
            }
        })
        this._handleNoEntriesHint()
    }

    _handleNoEntriesHint() {
        if (this.curriculumTarget.querySelectorAll(':scope > div:not(.visually-hidden)').length === 0) {
            this.curriculumTarget.appendChild(this._noEntriesHint)
        } else if (this.curriculumTarget.contains(this._noEntriesHint)) {
            this.curriculumTarget.removeChild(this._noEntriesHint)
        }
    }
}
