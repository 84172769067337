class DomParserSupport {
    static get isSupported() {
        if (!window.DOMParser) return false
        let parser = new DOMParser()
        try {
            parser.parseFromString('x', 'text/html')
        } catch (_error) {
            return false
        }
        return true
    }

    static parse(text) {
        const parser = new DOMParser()
        let documentFragment = new DocumentFragment();
        let doc = parser.parseFromString(text, 'text/html')
        const children = Array.from(doc.querySelector('body').children)
        Array.prototype.forEach.call(children, (element, _index) => {
            documentFragment.appendChild(element);
        });

        return documentFragment
    }
}

export default DomParserSupport
