// Entry point for the build script in your package.json
import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
// import * as ActiveStorage from '@rails/activestorage'
// import 'channels'

Rails.start()
window.Rails = Rails
// Turbolinks.start()
// ActiveStorage.start()

import './controllers'
import './components/font_awesome'
import './components/utility/print_iframe'

document.addEventListener('DOMContentLoaded', function (_event) {
    // Placeholder
})
