import {Controller} from '@hotwired/stimulus'
import {Dropdown} from 'bootstrap'

export default class extends Controller {
    connect() {
        new Dropdown(this.element, {
            boundary: document.body,
            reference: 'toggle',
            // Use below code when actually understanding how popover.js uses modifiers... ffs...
            // popperConfig: function (_defaultBsPopperConfig) {
            //     console.log(_defaultBsPopperConfig)
            //     // use defaultBsPopperConfig if needed...
            //     return {
            //         modifiers: [
            //             {
            //                 name: 'preventOverflow',
            //                 options: {
            //                     enabled: false
            //                 }
            //             }
            //         ]
            //     }
            // }
        })
    }
}
